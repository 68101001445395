import React from "react";
import "./App.css";
import Hulk from "./assests/website.png";
import Tokens from "./assests/tokens.png";
import Bottom from "./assests/bottom.png";
import bottomVideo from "./assests/IMG_1945.gif";
import { FaInstagram, FaTelegram, FaTwitter } from "react-icons/fa";

function App() {
  return (
    <div className="App">
      <img className="Hulk" src={Hulk} alt="Hulk" />
      <img className="Tokens" src={Tokens} alt="Tokens" />
      <div className="bottom-container">
        <img
          className="bottom"
          src={bottomVideo}
          alt="Bottom"
          autoPlay
          muted
          loop
        />
      </div>
      <footer>
        <ul className="social-icons">
          <li>
            <a href="https://www.instagram.com/holksolana?igsh=MThjN2Z3aW9idWl0dw%3D%3D&utm_source=qr">
              <FaInstagram />
            </a>
          </li>
          <li>
            <a href="https://t.me/holksolanainvite">
              <FaTelegram />
            </a>
          </li>
          <li>
            <a href="https://x.com/holksmashsolana?s=21&t=qJpVwFxvVxDQ27o4Zz-s_g">
              <FaTwitter />
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
}

export default App;
